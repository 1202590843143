import React from 'react';

interface GlobalContextInterface {
  hideHeaderBanner: boolean;
  setHideHeaderBanner: (value: boolean) => void;
}

export const GlobalContext = React.createContext<GlobalContextInterface>({
  hideHeaderBanner: false,
  setHideHeaderBanner: () => {},
});
