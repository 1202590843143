import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './src/styles/index.css';
import PreviewImage from './src/images/PreviewImage.png';
import { GlobalContext } from './src/context/globalContext';
import { ONE_DAY_IN_MS } from './src/utils/dates';
import { PromoPopup } from './src/components/common/PromoPopup';

const App = ({
  element,
}: {
  element: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}) => {
  const [hideHeaderBanner, setHideHeaderBanner] = useState(false);
  const [showPromoPopup, setShowPromoPopup] = useState(false);
  // useEffect(() => {
  //   const activatePopup = () => {
  //     setShowPromoPopup(true);
  //     localStorage.setItem(
  //       'promo-popup-next-appear',
  //       String(new Date().getTime() + ONE_DAY_IN_MS),
  //     );
  //   };

  //   if (
  //     !localStorage.getItem('promo-popup-next-appear') ||
  //     new Date().getTime() > Number(localStorage.getItem('promo-popup-next-appear'))
  //   ) {
  //     setTimeout(() => activatePopup(), 15000);
  //   }
  // }, [showPromoPopup]);

  // const closePopup = () => {
  //   setShowPromoPopup(false);
  // };

  const globalContextValue = {
    hideHeaderBanner,
    setHideHeaderBanner
  };

  return (
    <>
      <Helmet>
        <meta name="description" content="Messaging APIs built different."></meta>
        <meta property="og:title" content="Telgorithm" />
        <meta property="og:type" content="article" />
        <meta property="og:image" content={PreviewImage} />
        <meta property="og:description" content="Messaging APIs built different." />
        <meta property="og:site_name" content="Telgorithm" />
        <meta property="twitter:title" content="Telgorithm" />
        <meta property="twitter:description" content="Messaging APIs built different." />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content={PreviewImage}></meta>
        <meta name="twitter:image:alt" content="Telgorithm" />
        <noscript>
          {`<img
              src="https://ws.zoominfo.com/pixel/627aea554f6f68001256c330"
              width="1"
              height="1"
              style={{ display: "none" }}
              alt="websights"
            ></img>`}
        </noscript>
        <noscript>
          {`<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=4003508&fmt=gif"></img>`}
        </noscript>
      </Helmet>
      <GlobalContext.Provider value={globalContextValue}>
        {/* {showPromoPopup && <PromoPopup handleBannerHide={closePopup} />} */}
        {element}
      </GlobalContext.Provider>
    </>
  );
};

const Wrapper = ({ element }) => {
  return <App element={element} />;
};

export const wrapRootElement = Wrapper;
